@font-face {
  font-family: 'Source Sans Pro';
  font-weight: 400;
  font-style: normal;
  src: url('../fonts/SourceSansPro-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-weight: 400;
  font-style: italic;
  src: url('../fonts/SourceSansPro-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-weight: 700;
  font-style: normal;
  src: url('../fonts/SourceSansPro-Bold.ttf') format('truetype');

}

@font-face {
  font-family: 'Source Sans Pro';
  font-weight: 700;
  font-style: italic;
  src: url('../fonts/SourceSansPro-BoldItalic.ttf') format('truetype');
}

body {
  margin: 0;
  background-color: black;
}